<!-- 引导下滑组件 -->
<template>
  <div v-show="showDescent" :class="['guide-descent', dark ? 'guide-descent-dark' : '', isSafari ? 'in-safari' : '']">
    <div class="guide-icon">
      <template v-if="dark">
        <ArrowWhite class="icon-1" />
        <ArrowWhite class="icon-2" />
      </template>
      <template v-else>
        <Arrow class="icon-1" />
        <Arrow class="icon-2" />
      </template>
    </div>
    <p :class="['guide-text', showText ? 'guide-text-visible' : '']">{{ guideText }}</p>
  </div>
</template>
<script setup lang="ts">
import { ILocaleValue } from "@/constant/i18n-country"
import Arrow from "@/assets/icons/common/arrow_down.svg"
import ArrowWhite from "@/assets/icons/common/arrow_down_white.svg"
interface IProps {
  onlyPcShow?: boolean
  dark?: boolean // 是否为深色主题
}
const props = withDefaults(defineProps<IProps>(), {
  onlyPcShow: true,
  dark: false
})
//
const { width } = useWindowSize()
const isPC = computed(() => width.value >= 1400)
const showDescent = computed(() => {
  if (props.onlyPcShow) {
    return isPC.value
  } else {
    return true
  }
})
const showText = ref(false)
const { path } = useRoute()
const isFeature = useFeaturePage(path)
// feature有的不翻译
const guideText = computed(() => {
  return isFeature && !hasFeatureTranslate()
    ? useTranslateI18n("newHome.hero.explore", [], "", ILocaleValue.en)
    : useTranslateI18n("newHome.hero.explore")
})
onMounted(() => {
  const tId = setTimeout(() => {
    showText.value = true
    clearTimeout(tId)
  }, 1000 * 4)
})
const { isSafari } = useDevice()
</script>
<style scoped lang="scss">
@keyframes scrollIcon {
  0% {
    opacity: 1;
    transform: scale(1.25);
  }
  50% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1.25);
  }
}
.guide-descent {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .guide-icon {
    display: flex;
    flex-direction: column;
    .icon-1 {
      animation: scrollIcon 2s ease-in-out 1.2s infinite;
      margin-bottom: 2px;
    }
    .icon-2 {
      animation: scrollIcon 2s ease-in-out 1.7s infinite;
    }
  }
  .guide-text {
    color: #000;
    text-align: center;
    font-family: Roboto-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0;
    transition: all 0.3s ease-in;
    &-visible {
      opacity: 1;
    }
  }
  &-dark {
    .guide-text {
      color: #fff;
    }
    &.in-safari {
      bottom: 56px;
    }
  }
  &.in-safari {
    @include device-max-width-600 {
      bottom: 36px;
    }
  }
}
</style>
